
import { getCurrentInstance } from 'vue';
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  setup: () => {
    const instance = getCurrentInstance();
    const { $config } = instance!.proxy;
    const siteUrl = $config?.SITE_URL;

    return {
      siteUrl,
    };
  },
});
